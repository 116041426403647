class ArrowStepCards {
    private cards: NodeListOf<HTMLElement>;
    private buttons: NodeListOf<HTMLButtonElement>;
  
    constructor(private element: HTMLElement) {
      this.cards = element.querySelectorAll<HTMLElement>('.acr-cards__card');
      this.buttons = element.querySelectorAll<HTMLButtonElement>('.asc-buttons__button');
      this.init();
    }
  
    private setActiveCards(targetCards: string[]): void {
      this.cards.forEach(card => {
        const position = card.dataset.cardPosition;
        if(!position) return;
        card.classList.toggle('active', targetCards.includes(position));
      });
    }
  
    private setActiveButton(activeButton: HTMLButtonElement): void {
      this.buttons.forEach(button => {
        button.classList.toggle('active', button === activeButton);
      });
    }
  
    private handleButtonClick = (event: Event): void => {
      const button = event.currentTarget as HTMLButtonElement;
      const targetCards = button.dataset.targetCards?.split(',') ?? [];
      this.setActiveCards(targetCards);
      this.setActiveButton(button);
    }
  
    private init(): void {
      this.buttons.forEach(button => {
        button.addEventListener('click', this.handleButtonClick);
      });
  
      if (this.buttons.length > 0) {
        const firstButton = this.buttons[0];
        const initialTargetCards = firstButton.dataset.targetCards?.split(',') ?? [];
        this.setActiveCards(initialTargetCards);
        this.setActiveButton(firstButton);
      }
    }
  }

  export default ArrowStepCards;